
import React from 'react'
import classNames from 'classnames';
import '../css/workwithus.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import Button from './Buttons'
  import {FAQ} from './FAQ'
  import { FaQuoteLeft } from 'react-icons/fa';
  import { Helmet } from 'react-helmet';
import { BsFillArrowUpLeftSquareFill } from 'react-icons/bs';
import ChatWidget from './ChatWidget';

const CircleCheck = <svg className="CircleCheck" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path></svg>

const Check = <svg className="Check" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path></svg>
const Cross = <svg className="Cross" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg>

class WhyWorkWithUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
      }

      componentDidMount = () => {
        window.scrollTo(0, 0)
      }

    render() {


      return (
        <div className="WhyWorkWithUsPage">
            <Helmet>
    <meta charSet="utf-8" />
      <title>Post a Job with ArchJobs </title>
        <link rel="canonical" href={"https://www.archjobs.co.uk/post-job"} />
        <meta name="description" content={"Attract top architectural talent with ArchJobs, your go-to job board specialising in UK architectural roles. When you post a job with us, we help to craft and optimise the job advert in order to maximise its reach, before advertising it across our site, newsletter and active social media channels." }/>
        <meta name="og:title" content={"Post a Job with ArchJobs"}/>
        <meta name="og:type" content={"website"}/>
        <meta name="og:image" content={"https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/archjobs_banner.png"}/>
        <meta name="og:url" content={"https://www.archjobs.co.uk/post-job"}/>
    </Helmet>
          <div className="WhyWorkWithUsBanner">
            <div className="WhyWorkWithUsBannerTextContainer">
              <h1 className="WhyWorkWithUsSectionTitle">Post a Job with ArchJobs</h1>
              <p className = "WhyWorkWithUsSectionSubtitle">
              Attract top architectural talent with ArchJobs, your go-to job board specialising in UK architectural roles. When you choose to advertise your job with us, we help craft and optimise the job advert to maximise its reach, then promote it across our site, weekly newsletter (2.4k subscribers) and LinkedIn profile (10.2k followers).
              </p>

          </div>
          <div className="WhyWorkWithUsBannerBG"> </div>
          </div>
          <div className="WhyWorkWithUsSection">
           <div className="WhyWorkWithUsSectionTitle">Choose a Package</div>
            <div className="WhyWorkWithUsSectionSubtitle">Simple, transparent pricing options</div>
             <div className="PricingPackages">
              <div to="/post?package=basic" className="PostJobPackage" id="basic" >
                <div className="PackageTitle">Basic</div>
                <div className="PackagePrice">£129</div>
                <div className="PackagePerAd">per ad</div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} A Job Advert live for 28 days</div>
                  <div className="PackageDescriptionText">{Check} Manual job ad review by our team (may include minor changes for performance)</div>
                  <div className="PackageDescriptionText">{Check} We check your job ad for gender bias</div>
                  <div className="PackageDescriptionText">{Check} Applications straight to your inbox or ATS</div>
                  <div className="PackageDescriptionText">{Check} Company Logo on your ad</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to="/post?package=basic">
                    <Button btnClassName="PostJobButton">Start Now</Button>
                  </Link>
                </div>
              </div>
              <div to="/post?package=basic" className="PostJobPackage" id="standard" >
                <div className="PackageTitle">Standard</div>
                <div className="PackagePrice">£149</div>
                <div className="PackagePerAd">per ad</div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Everything from the Basic Package</div>
                  <div className="PackageDescriptionText">{Check} Get mentioned in our weekly newsletter</div>
                  <div className="PackageDescriptionText">{Check} Make amendments or close your ad early </div>
                  <div className="PackageDescriptionText">{Check} We help to improve and optimise your job ad and offer guidance on how to avoid common mistakes </div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to="/post?package=standard">
                    <Button btnClassName="PostJobButton">Start Now</Button>
                  </Link>
                </div>
              </div>
              <div to="/post?package=basic" className="PostJobPackage" id="premium" >
                <div className="PackageTitle">Premium</div>
                <div className="PackagePrice">£189</div>
                <div className="PackagePerAd">per ad</div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Everything from the Standard Package</div>
                  <div className="PackageDescriptionText">{Check} Featured listing at the top of our page</div>
                  <div className="PackageDescriptionText">{Check} Your job ad will be shared across our LinkedIn (10.2k+ followers)</div>
                  <div className="PackageDescriptionText">{Check} Advice on salary and benefit packages and find out how competitive they are</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to="/post?package=premium">
                    <Button btnClassName="PostJobButton">Start Now</Button>
                  </Link>
                </div>
              </div>
              <p/>
            </div>
            <p/>
            <p><strong>P.S. If you are looking for a different package, please contact us at hello@archjobs.co.uk</strong></p>
            <p/>
            <div className="WhyWorkWithUsSection GreySection">
          <div className="WhyWorkWithUsSectionTitle">What happens after you purchase a package?</div>
          <div className="WhyWorkWithUsSectionReasons"> 
            <p>When you submit a request to advertise a role with us, our experienced team takes the following steps to ensure the success of your job ad.</p>

            <p>First, we review the ad to guarantee its quality and suitability for our job board. Depending on the package you've purchased, we may offer suggestions to optimise the ad for maximum effectiveness. If we feel there are any details that could enhance the ad's appeal to job seekers, we’ll promptly reach out to you for confirmation.</p>

            <p>Once everything is finalised, <strong>our team uploads the ad</strong>, which includes your company logo for added visibility. You don't need to take any further action, and your ad will typically go live within 6 hours. You’ll receive an email with a link to the ad, and please feel free to request any minor changes to ensure it meets your expectations.</p>
          </div>
          <div className="StartNowButtonContainer StartNowButtonPaddedBottom">
                <Link to="/post?package=standard">
                  <Button btnClassName="PostJobButton">Get Started</Button>
                </Link>
            </div>
        </div>
            <div className="WhyWorkWithUsSection GreySection">
              <div className="WhyWorkWithUsSectionTitle">Why Advertise With ArchJobs?</div>
              <div className="TaylrHomePageFeatures">
                     <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">✔ Targeted Audience and High Traffic</div>
                            <div className="TaylrHomePageFeatureDescription">ArchJobs attracts <strong>5.9k+ unique monthly visitors</strong>, providing access to a highly targeted audience within the built environment industry, increasing relevant applicants.</div>
                        </div>
                    </div>
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">✔ Time-Saving</div>
                            <div className="TaylrHomePageFeatureDescription">Just submit your job brief, and our dedicated team will personally <strong>review, enhance, and post your ad on our job board</strong>. It's a hassle-free way to save you time and effort.</div>
                        </div>
                    </div>
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">✔ Job Ad Optimisation</div>
                            <div className="TaylrHomePageFeatureDescription">Our experienced team will <strong>enhance and optimise your job ad</strong>, ensuring it's well-written, includes all relevant details, and is designed to help job seekers make informed decisions.</div>
                        </div>
                    </div>
                    {/* <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">✔ High Website Traffic</div>
                            <div className="TaylrHomePageFeatureDescription">We attract 5.9k+ unique monthly visitors, optimising ads for Google to boost your practice's visibility to job seekers.</div>
                        </div>
                    </div>
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">✔ We Help Write Your Job Advert</div>
                            <div className="TaylrHomePageFeatureDescription">We understand what job seekers look for in ads and can offer our guidance.</div>
                        </div>
                    </div> */}
                    <div className="TaylrHomePageFeature">
                          <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">✔ Seamless Application Process</div>
                            <div className="TaylrHomePageFeatureDescription">Whether you're a small or large practice, you can receive applications either directly in <strong>your inbox</strong> or through your <strong>own Applicant Tracking System (ATS)</strong>.</div>
                        </div>
                    </div>
                    
                   
              </div>
              <div className="StartNowButtonContainer StartNowButtonPaddedBottom">
                <Link to="/post?package=standard">
                  <Button btnClassName="PostJobButton">Advertise with Us</Button>
                </Link>
            </div>

          </div>      
          </div>

          <div className="WhyWorkWithUsSectionTestimonials">
            <div className="WhyWorkWithUsSectionTitle">Testimonials</div>
              <div className="WhyWorkWithUsSectionSubtitle">Hear from our happy clients</div>
                <div className="WhyWorkWithUsTestimonials">
            <     div className="WhyWorkWithUsTestimonial">
                    <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                    <div className="WhyWorkWithUsTestimonialText">
                        <p>ArchJobs have been so helpful and efficient with getting our job adverts out there, with one quick email! </p>
                        <p>They go the extra mile by not only posting on their website, but also posting weekly reminders on their LinkedIn account. This has directed applicants straight to our ATS system, with one simple click. </p>   
                        <p>They have provided me with lots of insightful and useful tips to make our job advertisement’s stand out and draw in appliers. </p>
                        <p>I would recommend ArchJobs to anyone in the architectural recruitment industry! </p>
                    </div>
                    <div className="ArchJobsTestimonialProfile">
                      <img className="TestimonialImage" src="/BDPImage.jfif" alt="BDP's Photo"></img>
                      <div className="ArchJobsTestimonialProfileText">
                          <div className="TestimonialName">Niamh Ward</div>
                          <div className="TestimonialTitle">Recruitment & HR Administrator</div>
                          <a className="TestimonialCompany"href="https://www.bdp.com/en/?utm_source=archjobs&utm_medium=testimonial&utm_campaign=archjobs" target="_blank">BDP</a>
                      </div>
                    </div>
                  </div>
             
                <div className="WhyWorkWithUsTestimonial">
                  <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                  <div className="WhyWorkWithUsTestimonialText">
                      <p>As a provider of BIM services to the construction industry, we have had the pleasure of working with Aylin at ArchJobs on numerous occasions. We highly recommend her job board to any clients seeking a dedicated and knowledgeable recruitment space. </p>
                      <p>Aylin's experience working with professionals in this industry is second to none. She has a deep understanding of the unique challenges facing architects and the firms that employ them. This knowledge allows her to provide valuable insights and guidance to her clients, ensuring that all job adverts are fully optimised to attract the right talents.</p>
                      <p>Overall, we highly recommend Aylin and ArchJobs to any clients seeking someone top-notch in the architectural recruitment industry. She is an outstanding partner, and we are confident that anyone who works with her will have a highly positive experience. </p>
                  </div>
                  <div className="ArchJobsTestimonialProfile">
                      <img className="TestimonialImage" src="/LewisDuellImage.jpg" alt="Lewis Duell's Photo"></img>
                      <div className="ArchJobsTestimonialProfileText">
                          <div className="TestimonialName">Lewis Duell</div>
                          <div className="TestimonialTitle">Associate</div>
                          <a className="TestimonialCompany"href="https://bimbox.co.uk//?utm_source=archjobs&utm_medium=testimonial&utm_campaign=archjobs" target="_blank">BIMBox</a>
                      </div>
                  </div>
                </div>

          <div className="WhyWorkWithUsTestimonial">
            <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
               <div className="WhyWorkWithUsTestimonialText">
                 <p>We were struggling to find suitable candidates for a Part 1 Architectural Assistant role and were glad to find ArchJobs. </p>
                 <p>Aylin kindly helped to adjust the wording of our advert to better target quality applicants and uploaded the listing with super efficiency and speed.  The service we received was professional but personal and we received numerous quality CV’s. </p>
                  <p>We have already recommended ArchJobs to colleagues and wouldn’t hesitate to return when we next need to hire. </p>
                </div>
                <div className="ArchJobsTestimonialProfile">
                  <img className="TestimonialImage" src="/KeriBarrImage.jfif" alt="Keri Barr's Photo"></img>
                    <div className="ArchJobsTestimonialProfileText">
                      <div className="TestimonialName">Keri Barr</div>
                        <div className="TestimonialTitle">Architect & Director</div>
                        <a className="TestimonialCompany"href="https://k-b-a.co.uk//?utm_source=archjobs&utm_medium=testimonial&utm_campaign=archjobs" target="_blank">KBA</a>
                      </div>
                  </div>
          </div>
                <div className="WhyWorkWithUsTestimonial">
                  <FaQuoteLeft className="WhyWorkWithUsTestimonialQuote"/>
                  <div className="WhyWorkWithUsTestimonialText">
                      <p>ArchJobs is a great platform to advertise for architecture roles, the applications come through  nice and tidy with the exact information you need to make an informed decision on the candidate. </p>
                      <p> I had a great response for Architectural Assistant and Architect roles and the quality of candidates were of high calibre. </p>
                      <p>I would surely recommend.</p>
                  </div>
                  <div className="ArchJobsTestimonialProfile">
                      <img src="/KelitaWoodImage.jfif" alt="Kelita Wood's Photo" className="TestimonialImage"></img>
                      <div className="ArchJobsTestimonialProfileText">
                          <div className="TestimonialName">Kelita Wood, Assoc CIPD</div>
                          <div className="TestimonialTitle">HR and Recruitment Coordinator</div>
                          <a className="TestimonialCompany"href="https://www.purcelluk.com//?utm_source=archjobs&utm_medium=testimonial&utm_campaign=archjobs" target="_blank">Purcell</a>
                      </div>
                  </div>
              </div>  
            </div>
            <p/>
        </div>
        {/* <div className="WhyWorkWithUsSection GreySection">
          <div className="WhyWorkWithUsSectionTitle">What happens after you purchase a package?</div>
          <div className="WhyWorkWithUsSectionReasons"> 
            <p>When you submit a request to advertise a role with us, our experienced team takes the following steps to ensure the success of your job ad.</p>

            <p>First, we review the ad to guarantee its quality and suitability to our job board. Next, we go a step further by optimising the ad for maximum effectiveness. If our team feels like there are any details or information needed to make the ad more appealing to potential job seekers, we'll reach out to you promptly.</p>

            <p>Once everything is in place, we upload the job ad, which includes your company logo for added visibility. You'll then receive an email with the link to the ad, and please note that you can always request minor changes to ensure your job ad meets your expectations.</p>
          </div>
          <div className="StartNowButtonContainer StartNowButtonPaddedBottom">
                <Link to="/post?package=standard">
                  <Button btnClassName="PostJobButton">Get Started</Button>
                </Link>
            </div>
        </div> */}
        <div className="WhyWorkWithUsSectionTitle">Are You a Hiring on a More Frequent Basis? Try Our Retainer Model!</div>
            <div className="WhyWorkWithUsSectionRetainer">
            <p>With our retainer model, you'll have full control over your monthly costs and the freedom to post all your architectural roles on ArchJobs without any additional fees.</p> 
            <p>It's the perfect solution for practices that hire more frequently and aim to save money.</p>
            {/* <p>To learn more get in touch with Aylin via aylin@archjobs.co.uk or call on 07723 329133.</p> */}
            <div className="AuthorImgContainer">
             <p>Drop Aylin an email at <a href="mailto:aylin@archjobs.co.uk">aylin@archjobs.co.uk</a>.</p>
              <img src="https://cdn.archjobs.co.uk/file/ArchJobs/recruiters/AylinLightBG_1x1.png" className="AuthorImg" alt="Aylin Round"></img>
              </div>
            </div>
          {/* <div className="StartNowButtonContainer StartNowButtonPaddedBottom">
                <Link to="/post?package=standard">
                  <Button btnClassName="PostJobButton">Advertise with us</Button>
                </Link>
          </div> */}
            <p/>
          <div className="WhyWorkWithUsSection ">
            <FAQ/>
          </div>
          <div className="WhyWorkWithUsSection Bottom">
            <div className="WhyWorkWithUsSectionTitle">Still have a question? Reach out to us via email</div>
            <div className="WhyWorkWithUsSectionSubtitle">Drop us an email at <a href="mailto:hello@archjobs.co.uk">hello@archjobs.co.uk</a></div>
            {/* <div className="WhyWorthWithUsHeaderButton">
              <Link to="/post">
                <Button btnClassName="PostJobButton">Contact Us</Button>
              </Link>
            </div> */}
          </div>
          <div className="BottomPadding"></div> 
          <ChatWidget />
        </div>
      )
    }
  }

  export default WhyWorkWithUs