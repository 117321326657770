
const ArticleData = {
    //template:
    "YourArticle": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/writing_cv.jpeg", //resize to 800px wide, ideally at least 500px high. recommend https://www.resizepixel.com for this (scale to 800px wide)
        thumbnail_img: "", //resize to 250px wide, 180px high. Resize to whichever is most limiting (ie. don't resize to 180px high if the image is then less than 250px wide)
        article_title: "Title", //should be less than 110 characters
        article_description: "",
        article_url: "https://www.archjobs.co.uk/articles/your-article", //the url that you create for your article
        author: "Aylin Round", //needs to match an author in AuthorData
        published: new Date(2022, 8, 10), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2022, 8, 10), //same format as above
        //stuff that is important for google
        main_img_1x1: "", // highest resolution article image, 1x1 height to width ratio. Recommend https://www.resizepixel.com/edit?act=crop 
        main_img_4x3: "",
        main_img_16x9: "",
    },
    "CoverLetter": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/cover_letter_image_800px.jpg", //resize to 800px wide, ideally at least 500px high. recommend https://www.resizepixel.com for this (scale to 800px wide)
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/cover_letter_image_250px.jpg", //resize to 250px wide, 180px high. Resize to whichever is most limiting (ie. don't resize to 180px high if the image is then less than 250px wide)
        article_title: "How to Structure an Architecture Cover Letter + 2 Examples", //should be less than 110 characters
        article_description: "Make a great first impression with your architecture job application using this guide to structure your cover letter. Get tips on what to avoid, 2 examples letters and more.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-structure-an-architecture-cover-letter", //the url that you create for your article
        author: "Aylin Round", //needs to match an author in AuthorData
        published: new Date(2024, 7, 12), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 7, 12), //same format as above
        //stuff that is important for google
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/cover_letter_image_1x1.jpg", // highest resolution article image, 1x1 height to width ratio. Recommend https://www.resizepixel.com/edit?act=crop 
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/cover_letter_image_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/cover_letter_image_16x9.jpg",
    },
    "WriteCV": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/writing_cv.jpeg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/writing_cv_full_thumbnail.jpg",
        article_title: "How to Write and Structure an Architecture CV",
        article_description: "Learn how to write a standout architecture CV with this guide. Get tips on structure, format, and keywords, and find out how to impress potential employers and beat Applicant Tracking Systems (ATS).",
        article_url: "https://www.archjobs.co.uk/articles/how-to-write-and-structure-your-architectural-cv",
        author: "Aylin Round",
        published: new Date(2024, 6, 9), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 6, 9),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/writing_cv_full_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/writing_cv_full_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/writing_cv_full_16x9.jpg",
    },
    "Portfolio": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/portfolio_image_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/portfolio_image_250px.jpg",
        article_title: "How to Prepare an Architecture Portfolio",
        article_description: "Maximise your chances of landing your dream architecture job with a winning portfolio. Learn what to include, how to structure it, and more in this guide.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-prepare-an-architecture-portfolio",
        author: "Aylin Round",
        published: new Date(2024, 5, 22), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 5, 22),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/portfolio_image_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/portfolio_image_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/portfolio_image_16x9.jpg",
    },
    "JobInterview": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Job_Interview_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Job_Interview_250px.jpg",
        article_title: "How to Prepare for an Architectural Job Interview",
        article_description: "Prepare for your architectural job interview with this guide. Learn how to answer common questions, do research, and ask the right questions at the end.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-prepare-an-architectural-job-interview",
        author: "Aylin Round",
        published: new Date(2023, 7, 14), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2023, 7, 14),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Job_Interview_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Job_Interview_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Job_Interview_16x9.jpg",
    },
    "Linkedin": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_image_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_image_250px.jpg",
        article_title: "Architectural Guide: Enhance your LinkedIn Profile in 10 Steps for more Visibility",
        article_description: "Optimise your LinkedIn profile and increase your visibility with these 10 easy steps to improve your chances of being found by hiring managers and expanding your professional network.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-optimise-an-architectural-linkedin-profile",
        author: "Aylin Round",
        published: new Date(2023, 11, 28), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2023, 11, 28),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_image_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_image_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_image_16x9.jpg",
    },
    "PayIncrease": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/payincrease_image_800px.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/payincrease_image_250px.png",
        article_title: "Architect's Guide: How to Negotiate A Pay Increase",
        article_description: "Get the salary you deserve with this guide to negotiating a pay increase. Learn what to say and do to confidently approach the subject and avoid common mistakes.",
        article_url: "https://www.archjobs.co.uk/articles/architects-guide-how-to-negotiate-a-pay-increase",
        author: "Aylin Round",
        published: new Date(2023, 10, 14), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2023, 10, 14),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/payincrease_image_1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/payincrease_image_4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/payincrease_image_16x9.png",
    },
    "CounterOffer": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/counter_offer_image_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/counter_offer_image_250px.jpg",
        article_title: "Architect's Guide: Should I Accept a Counter-Offer?",
        article_description: "Discover whether accepting a counter-offer from your current employer is the right choice with this insightful guide. Learn about the pros and cons of accepting and get the advice you need to make an informed decision.",
        article_url: "https://www.archjobs.co.uk/articles/architects-guide-should-i-accept-a-counter-offer",
        author: "Aylin Round",
        published: new Date(2023, 8, 20), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2023, 8, 20),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/counter_offer_image_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/counter_offer_image_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/counter_offer_image_16x9.jpg",
    },
    "Resign": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/resign_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/resign_250px.jpg",
        article_title: "Architect's Guide: How to Hand in Your Notice & Templates",
        article_description: "Learn how to hand in your notice smoothly and professionally with this guide. Get answers to common questions and access templates for a stress-free transition.",
        article_url: "https://www.archjobs.co.uk/articles/architects-guide-how-to-hand-in-your-notice",
        author: "Aylin Round",
        published: new Date(2023, 8, 28), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2023, 8, 28),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/resign_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/resign_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/resign_16x9.jpg",
    },
    "CommonInterviewQuestions": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/common_interview_questions_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/common_interview_questions_250px.jpg",
        article_title: "Architect's Interview Guide: Answering Questions & Understanding Purpose",
        article_description: "Prepare for common interview questions and impress your interviewer with this guide. Learn how to answer questions effectively and boost your confidence.",
        article_url: "https://www.archjobs.co.uk/articles/architects-guide-how-to-answer-common-interview-questions",
        author: "Aylin Round",
        published: new Date(2023, 8, 8), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2023, 8, 8),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/common_interview_questions_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/common_interview_questions_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/common_interview_questions_16x9.jpg",
    },

    "SeniorCV": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/senior_cv_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/senior_cv_250px.jpg",
        article_title: "How to Write and what to Include in a Senior Architectural CV",
        article_description: "Learn how to write an effective senior architectural CV with this guide. Get tips on highlighting your experience, leadership abilities, and tailoring your CV to the job.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-write-a-senior-architectural-cv",
        author: "Aylin Round",
        published: new Date(2024, 4, 18), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 4, 18),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/senior_cv_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/senior_cv_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/senior_cv_16x9.jpg",
    },

    // "JobAd": {
    //     main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/how_to_write_a_job_ad_800px.jpg",
    //     thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/how_to_write_a_job_ad_250px.jpg",
    //     article_title: "How to Write an Architectural Job Advert and Attract more Talent",
    //     article_description: "Discover how to write job adverts that attract top talent with this comprehensive guide. From structure to avoiding gender bias, get tips for effective job adverts.",
    //     article_url: "https://www.archjobs.co.uk/articles/how-to-write-an-architectural-job-advert",
    //     author: "Aylin Round",
    //     published: new Date(2023, 2, 6), //Year, Month, Day. Months start at 0 and go to 11 
    //     modified: new Date(2023, 2, 6),
    //     main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/how_to_write_a_job_ad_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
    //     main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/how_to_write_a_job_ad_4x3.jpg",
    //     main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/how_to_write_a_job_ad_16x9.jpg",
    // },

    "ATS": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ATS_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ATS_250px.jpg",
        article_title: "How to Create an ATS-Friendly Architectural CV",
        article_description: "Learn how to create an ATS-friendly Architectural CV and increase your chances of landing your dream job. Get practical tips to beat the ATS system in this article.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-create-an-ats-friendly-architectural-cv",
        author: "Aylin Round",
        published: new Date(2023, 9, 27), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2023, 9, 27),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ATS_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ATS_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ATS_16x9.jpg",
    },

    "ChooseOffers": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/choose_offer_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/choose_offer_250px.jpg",
        article_title: "Architectural Guide: How to Choose Between Job Offers",
        article_description: "Choosing between similar job offers in architecture? Learn how to compare, assess, and make the right decision for your career goals. Find guidance here.",
        article_url: "https://www.archjobs.co.uk/articles/architectural-guide-how-to-choose-between-job-offers",
        author: "Aylin Round",
        published: new Date(2023, 5, 16), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2023, 5, 16),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/choose_offer_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/choose_offer_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/choose_offer_16x9.jpg",
    },

    "WorkingWithRecruiters": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/WorkingRecruiters800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/WorkingRecruiters250px.jpg",
        article_title: "How an Architectural Recruiter Can Help Find Your Next Role",
        article_description: "Unlock job market opportunities with a recruiter. Leverage their expertise, networks, and support to find your ideal job and advance your career.",
        article_url: "https://www.archjobs.co.uk/articles/how-an-architectural-recruiter-can-help-find-your-next-role",
        author: "Aylin Round",
        published: new Date(2023, 5, 25), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2023, 5, 25),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/WorkingRecruiters1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/WorkingRecruiters4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/WorkingRecruiters16x9.jpg",
    },

    "Part1": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part1_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part1_250px.jpg",
        article_title: "How to Get Your First Part 1 Architectural Assistant Job",
        article_description: "Understand how your network, CV, Cover Letter, and Sample Portfolio play important roles, along with multiple examples.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-find-a-part-1-architectural-assistant-role",
        author: "Aylin Round",
        published: new Date(2024, 10, 30), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 10, 30),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part1_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part1_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part1_16x9.jpg",
    },

    "Part2": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part2_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part2Jobs_250px.jpg",
        article_title: "How to Get Your Next Part 2 Architectural Assistant Job",
        article_description: "Learn how your network, CV, Cover Letter, and Sample Portfolio play a crucial part in finding your next role + multiple examples.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-find-your-next-part-2-architectural-assistant-role",
        author: "Aylin Round",
        published: new Date(2024, 10, 18), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 10, 18),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part2Jobs1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part2Jobs4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part2Jobs16x4.jpg",
    },

    "HowToFindARecruiter": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/how_to_find_a_recruiter_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/how_to_find_a_recruiter_250px.jpg",
        article_title: "How To Find an Architectural Recruiter And Tips For Working With One",
        article_description: "Learn how to find an architectural recruiter, understand how to work with one, and how they can help you find your next role.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-find-an-architectural-recruiter-and-work-with-one",
        author: "Aylin Round",
        published: new Date(2023, 6, 23), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2023, 6, 23),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/how_to_find_a_recruiter_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/how_to_find_a_recruiter_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/how_to_write_a_job_ad_16x9.jpg",
    },

    "OverseasArchitect": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Moving_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Moving_250px.jpg",
        article_title: "Career Guide: Moving to the UK as an Overseas Architect",
        article_description: "Essential Guide for Overseas Architects wanting to move to the UK: Everything you need to know, from ARB to salary, location, application documents, and more.",
        article_url: "https://www.archjobs.co.uk/articles/moving-to-the-uk-as-an-overseas-architect",
        author: "Aylin Round",
        published: new Date(2025, 0, 1), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2025, 0, 1),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Moving_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Moving_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Moving_16x9.jpg",
    },
    
    // "ATStudy": {
    //     main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ATStudy800px.jpg",
    //     thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ATStudy250px.jpg",
    //     article_title: "UK Architectural Technology Studies: Where to Study and Costs 2023/24",
    //     article_description: "Find out where you can study to become an Architectural Technologist in the UK and how much it will cost for both UK and International Students.",
    //     article_url: "https://www.archjobs.co.uk/articles/where-to-study-architectural-technology-in-the-uk",
    //     author: "Aylin Round",
    //     published: new Date(2023, 7, 15), //Year, Month, Day. Months start at 0 and go to 11 
    //     modified: new Date(2023, 7, 15),
    //     main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ATStudy1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
    //     main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ATStudy4x3.jpg",
    //     main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ATStudy16x9.jpg",
    // },


    "Networking": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/networking_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/networking_250px.jpg",
        article_title: "Where to find Architectural Networking Events in the UK",
        article_description: "Unlock Architectural Networking Opportunities: Grow your network for career success, connect with like-minded professionals, and develop new skills in the UK.",
        article_url: "https://www.archjobs.co.uk/articles/where-to-find-architectural-networking-events-uk",
        author: "Aylin Round",
        published: new Date(2024, 0, 29), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 0, 29),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/networking_1x1px.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/networking_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/networking_16x9.jpg",
    },

    "LivingWages": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/livingwage_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/livingwage_250px.jpg",
        article_title: "UK Architectural Salaries, Living Wages, Unpaid Overtime and more",
        article_description: "From low salaries to unpaid overtime. Learn about Living Wage vs. National Minimum Wage, RIBA Chartered Practice compensation, and essential tips for protection.",
        article_url: "https://www.archjobs.co.uk/articles/uk-architectural-salaries-and-living-wages",
        author: "Aylin Round",
        published: new Date(2025, 0, 1), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2025, 0, 1),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/livingwage_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/livingwage_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/livingwage_16x9.jpg",
    },
    "ArchAdemia": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemiapromo_800px.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemiapromo_1x1.png",
        article_title: "The all-in-one training platform for Architects & Students",
        article_description: "A place for creatives to elevate skills, industry knowledge, confidence & career success! Our platform is perfect for design students, architects, interior designers & archviz artists of all skill levels.",
        article_url: "https://www.archjobs.co.uk/articles/archademia",
        author: "Adam Morgan",
        published: new Date(2024, 1, 22), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 1, 22),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemiapromo_1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemiapromo_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemiapromo_16x9.jpg",
    },
    "DubaiGuide": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Dubai_Guide-Article_800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Dubai_Guide-Article_250px.jpg",
        article_title: "Essential Guide for UK Architects Moving to Dubai",
        article_description: "Are you thinking of relocating to Dubai? Before you pack your bags, read this guide to understand the job market, renting, living, working, and more in Dubai.",
        article_url: "https://www.archjobs.co.uk/articles/guide-for-uk-architects-moving-to-dubai",
        author: "Aylin Round",
        published: new Date(2024, 11, 30), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 11, 30),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Dubai_Guide-Article_1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Dubai_Guide-Article_4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Dubai_Guide-Article_16x9.jpg",
        dubai: true,
    },

    "EmploymentContract": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/employmentcontract-800px.jpg",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/employmentcontract-250px.jpg",
        article_title: "Everything You Need to Know Before Signing an Employment Contract",
        article_description: "Essential tips for navigating your job contract in the UK. Learn why reading the fine print is crucial, what to watch for, and how to handle discrepancies. Gain insights to avoid common pitfalls.",
        article_url: "https://www.archjobs.co.uk/articles/everything-you-need-to-know-before-signing-an-employment-contract",
        author: "Aylin Round",
        published: new Date(2024, 6, 16), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 6, 16),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/employmentcontract-1x1.jpg", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/employmentcontract-4x3.jpg",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/employmentcontract-16x9.jpg",
    },

    "DubaiCV": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Jane_Doe_Dubai_800.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Jane_Doe_Dubai_250.png",
        article_title: "How to Write and Structure an Architecture CV fit for the Dubai market?",
        article_description: "Discover essential tips for crafting a standout architecture CV that meets the demands of the Dubai job market for maximum impact.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-write-an-architecture-cv-for-the-dubai-market",
        author: "Aylin Round",
        published: new Date(2024, 9, 9), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 9, 9),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Jane_Doe_Dubai_1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Jane_Doe_Dubai_4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Jane_Doe_Dubai_16x9.png",
        dubai: true,
    },

    "DubaiPortfolio": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/portfolio_dubai_800.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/dubaiportfolio 250.png",
        article_title: "Crafting the Perfect Architectural Portfolio for Dubai’s Competitive Market",
        article_description: "Understand the key differences and tailor your architecture portfolio to meet the unique demands of Dubai's competitive market.",
        article_url: "https://www.archjobs.co.uk/articles/crafting-the-perfect-architectural-portfolio-for-dubais-competitive-market",
        author: "Aylin Round",
        published: new Date(2024, 9, 10), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 9, 10),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/portfolio_dubai_1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/portfolio_dubai_4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/portfolio_dubai_16x9.png",
        dubai: true,
    },

    "UKQuickGuideCV": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Checklist 800.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Checklist 250new.png",
        article_title: "CV Checklist: What to Include in Your UK Architectural CV",
        article_description: "Here’s our 3 minute checklist of what to include in your architectural CV when applying for roles in the UK. We take you through each section step by step.",
        article_url: "https://www.archjobs.co.uk/articles/checklist-what-to-include-in-your-uk-architectural-cv",
        author: "Aylin Round",
        published: new Date(2024, 10, 4), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 10, 4),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Checklist 1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Checklist 4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Checklist 16x9.png",
        dubai: true,
    },
    "DubaiQuickGuideCV": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/CV Dubai Checklist 800.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/CV Dubai Checklist 180.png",
        article_title: "CV Checklist: What to Include in a Dubai Architectural CV",
        article_description: "Not sure what to include in your architectural CV when applying for roles in Dubai? No worries! Here’s our 3 minute checklist to help you identify the important details.",
        article_url: "https://www.archjobs.co.uk/articles/checklist-what-what-to-include-in-a-Dubai-architectural-cv",
        author: "Aylin Round",
        published: new Date(2024, 10, 5), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 10, 5),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/CV Dubai Checklist 1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/CV Dubai Checklist 4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/CV Dubai Checklist 16x9.png",
        dubai: true,
    },

    "QuickGuideSamplePortfolio": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/QuickGuidePortfolio 800.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/QuickGuidePortfolio 250.png",
        article_title: "3 Minute Guide: What to Include in Your Architectural Sample Portfolio",
        article_description: "Quick and simple guide on what to include in your Sample Portfolio when applying for architectural roles in the UK and Dubai.",
        article_url: "https://www.archjobs.co.uk/articles/guide-what-to-include-in-your-architectural-sample-portfolio",
        author: "Aylin Round",
        published: new Date(2024, 10, 6), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 10, 6),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/QuickGuidePortfolio 1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/QuickGuidePortfolio 4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/QuickGuidePortfolio 16x9.png",
        dubai: true,
    },

    "UKChecklistContract": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Checklist Contract 800.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Checklist Contract 250.png",
        article_title: "3 Minute Checklist: Key Details to Check Before Signing Your Contract",
        article_description: "Before you sign your contract, make sure you’ve checked these key details. This 3 minute checklist will help you identify which details to look out for before you join an architectural practice.",
        article_url: "https://www.archjobs.co.uk/articles/key-details-to-check-before-signing-your-architectural-contract",
        author: "Aylin Round",
        published: new Date(2024, 10, 15), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 10, 15),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Checklist Contract 1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Checklist Contract 4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Checklist Contract 16x9.png",
        dubai: true,
    },

    "UKChecklistOverseas": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Overseas Guide 800px.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Overseas Guide 250.png",
        article_title: "Quick Guide: Everything Architects Need to Know Before Moving to the UK",
        article_description: "Discover everything you need to know about moving to the UK as an architect, from job titles and visas to salaries and application tips. Read our 4 minute quick guide.",
        article_url: "https://www.archjobs.co.uk/articles/everything-architects-need-to-know-before-moving-to-the-uk",
        author: "Aylin Round",
        published: new Date(2024, 10, 25), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 10, 25),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Overseas Guide 1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Overseas Guide 4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UK Overseas Guide 16x9.png",
        dubai: true,
    },

    "Portfolio2025": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Portfolio_800px.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Portfolio_small.png",
        article_title: "How to Prepare an Architecture Portfolio (2025)",
        article_description: "Discover how to prepare an architecture portfolio that showcases your skills, experience and creativity. Get tips on what to include and why you should have a Sample Portfolio.",
        article_url: "https://www.archjobs.co.uk/articles/how-to-prepare-an-architecture-portfolio-2025",
        author: "Aylin Round",
        published: new Date(2025, 0, 23), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2025, 0, 23),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Portfolio_1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Portfolio_4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Portfolio_16x9.png",
    },


    }

const AuthorData = {
    "Aylin Round": {
        name: "Aylin Round",
        profile_img: "https://cdn.archjobs.co.uk/file/ArchJobs/recruiters/AylinLightBG_1x1.png",
        description: "Hi, I'm Aylin, the owner and founder of ArchJobs. With over 5 years of experience in the architectural industry across the UK and Dubai markets, I love creating content that shares valuable insights and offers the guidance you need to advance your architectural career.",
        profile_page: "https://www.archjobs.co.uk/author/aylin-round",
        linkedin: "https://www.linkedin.com/in/aylin-round/",
    },
     "Adam Morgan": {
            name: "Adam Morgan",
            profile_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/adam-morgan.jpg",
            description: "Hi, I’m Adam. I am the founder and director of Studio RBA and Co-founder of ArchAdemia., a team of 8 architects and CGI artists in the city centre of Liverpool, UK. The office is experienced in a wide range of building types and procurement routes, successfully winning projects with contract values of up to £80 million. We work for a broad spectrum of public and private sector clients across the country. I have always had a passion for teaching aspiring and young architects. I offer support to emerging young architects through the RIBA mentoring programme and am also a visiting architectural critic and tutor for Liverpool John Moores University.",
            profile_page: "https://www.archjobs.co.uk/author/adam-morgan",
            linkedin: "https://www.linkedin.com/in/adam-morgan-34aa8345/",
    }
}

export default {
    ArticleData,
    AuthorData,
}