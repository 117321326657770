import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


//first go to ArticleData.js and add all the relavant data
//next, rename "YourArticle" below to what you named the article data section
const thisArticleData = "Portfolio2025"


//rename YourArticle below to your article name
export default class Portfolio2025 extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }

    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
    render(){
        return <ArticleFrame ArticleDataName={thisArticleData}>
            <p>Your portfolio plays an important role throughout your architecture career. It can make or break your chances of landing your next dream job or securing a new client. In this article, I&rsquo;ll cover some important questions: What is an architectural portfolio used for? What should I include in my portfolio? What are the differences between a Sample and Full Portfolio? How should I structure my portfolio? and more... Let&rsquo;s dive in.</p>
            <p>Note: This article focuses on the architecture portfolio for employment rather than an educational portfolio.</p>
            <p/>
            <h3>The structure of this article</h3>
            <ol>
            <li>What is an Architecture Portfolio?</li>
            <li>What Should You Include in Your Portfolio?</li>
            <li>How to Structure Your Portfolio</li>
            <li>Formats for Your Portfolio: Printed, PDF or Online?</li>
            <li>Final Checks</li>
            </ol>
            <p/>
            <p/>
            <h2>1. What is an Architecture Portfolio?&nbsp;</h2>
            <p>An architectural portfolio is a unique body of work that represents your experience as a designer. It should showcase your best and most recent work that you can share with potential employers or clients. The portfolio provides a true reflection of your architectural skills, your design approach, technical abilities and how you&rsquo;ve evolved in your career.</p>
            <p/>
            <p/>
            <h3>Sample Portfolio vs Full Portfolio</h3>
            <p>There are 2 different types of portfolios: the Sample Portfolio and the Full Portfolio.</p>
            <ul>
            <li>Sample Portfolio: This goes hand in hand with your CV and should be no longer than 10-12 pages, with a file size of no more than 10MB. It must complement your CV, i.e. if you mentioned your design and technical skills, the hiring manager will expect to see evidence of that in your sample portfolio.</li>
            <li>Full Portfolio: Once you receive an interview invite, the full portfolio comes into play. This is your chance to showcase more of your work. It can be up to 50 pages long, with detailed examples of key projects you&rsquo;ve worked on, your involvement at various stages, completed projects (if applicable), construction detailing, creative problem-solving skills and design expertise.</li>
            </ul>
            <p><strong><a href="https://www.linkedin.com/in/andy-shaw-riba/">Andy Shaw</a>, Managing Partner at AMA</strong>, shares his thoughts on sample portfolios: <em>As an employer, this is what I am looking for. A few pages of the best work is enough, I don&rsquo;t have time to go through 30 pages when so many come in. Even more &ndash; if someone emails a crazy big file over 20MB, it's a red flag that they don&rsquo;t know how to handle digital information well.</em>"</p>
            <p>Source:<a href="https://www.linkedin.com/feed/update/urn:li:activity:7092051336413724672/"> LinkedIn post on Sample Portfolios</a></p>
            <p/>
            <p/>
            <h3>General Sample Portfolio Formatting Tips</h3>
            <ul>
            <li>Length: Ideally under 10 - 12 pages</li>
            <li>File type and size: PDF and under 10MB</li>
            <li>Cover Page: Include your Full Name, Job Title, Phone number and Email</li>
            <li>Content Page: Ideally your 2nd page will be a content page</li>
            <li>Last Page: Thank you and include your Full Name, Job Title, Phone number and Email</li>
            <li>Include around 4 projects</li>
            <li>Font type: Easy-to-read fonts like Arial, Times New Roman, or Calibri</li>
            <li>Font size: 10-11 for the normal text and bigger font sizes for any Heading/Subheaders</li>
            <li>Structure: Start with your most recent work</li>
            <li>Naming your file: First and last name - Sample Portfolio. (e.g. Jane Doe - Sample Portfolio)</li>
            </ul>
            <p/>
            <p/>
            <h3>General Full Portfolio Formatting Tips</h3>
            <ul>
            <li>Length: Ideally under 50-60 pages (depending on your level of experience)</li>
            <li>File type and size: PDF and ideally under 20MB</li>
            <li>Cover Page: Include your Full Name, Job Title, Phone number and Email</li>
            <li>Content Page: Ideally your 2nd page will be a content page</li>
            <li>Last Page: Thank you and include your Full Name, Job Title, Phone number and Email</li>
            <li>Font type: Easy-to-read fonts like Arial, Times New Roman, or Calibri</li>
            <li>Font size: 10-11 for the normal text and bigger font sizes for any Heading/Subheaders</li>
            <li>Structure: Start with your most recent work or with the most relevant project</li>
            <li>Naming your file: First and last name - Portfolio. (e.g. Jane Doe - Portfolio)</li>
            </ul>
            <p/>
            <p/>
            <h3>Adobe InDesign to Create Your Portfolio</h3>
            <p>When it comes to designing the layout for your portfolio, Adobe InDesign provides simple tools that allow you to easily create, drag, drop and edit documents.</p>
            <p>If you need to edit any drawings or images, I suggest using Photoshop and Illustrator.</p>
            <p><strong>💡 Tip:</strong> Never send a PowerPoint presentation or multiple individual drawings/images to a hiring manager, as it may create a poor first impression.</p>
    
            <p/>
            <p/>
            <h2>2. What Should You Include in Your Portfolio?&nbsp;</h2>
            <p>Depending on your level of experience, your portfolio will vary. Employers will expect different things based on where you are in your career.</p>
            <p/>
            <h3>For Students/Interns/Architectural Assistants</h3>
            <p>If you&rsquo;re an architecture student or intern, your portfolio should showcase your academic work, creativity, design sketches, model-making abilities, renders and how you approach design challenges. Employers will look for an understanding of the RIBA stages and how you think about design.</p>
            <h3>For Early Career Architects (1-3 Years Experience)</h3>
            <p>Your portfolio should feature live projects at various stages and demonstrate both design and technical drawings (i.e. construction detailing, floor plans, sections, or elevations). Highlight how you&rsquo;ve evolved as an architect, and don&rsquo;t forget to include some university work if relevant.</p>
            <p/>
            <h3>For Mid-Level Architects (3-8 Years Experience)</h3>
            <p>By now, hiring managers expect to see a well-rounded architect. Your portfolio should reflect the diversity of your experience, whether you&rsquo;ve worked on complex schemes, across different sectors, or found your niche. Be sure to include site experience and completed projects, but there's no need to include university work.</p>
            <p/>
            <h3>For Senior Architects (8+ Years Experience)</h3>
            <p>By this stage, your portfolio should demonstrate that you have overseen projects and teams, potentially larger and more complex ones, as well as your technical expertise. Focus on recent projects you&rsquo;ve led, from pre-design through construction. Avoid including work from over 10 years ago unless it&rsquo;s critical for the position.</p>
            <p>&nbsp;</p>
            <p/>
            <h2>3. How to Structure Your Portfolio&nbsp;</h2>
            <p>When structuring your portfolio, the key is to make a strong first impression, show your best work and present it in a clear and easy to understand way. Here's how:</p>
            <p/>
            <p/>
            <h3>Cover Page</h3>
            <p>A strong cover page is essential for both your Full and Sample Portfolio. It&rsquo;s your first chance to make a great impression, so keep it clean and simple.</p>
            <p>Include your full name, job title and contact information. For added impact, you could use a hand sketch or a minimalistic design to reflect your style. Remember, the cover page sets the tone, so make sure it&rsquo;s professional and aligned with the overall look of your portfolio.</p>
            <p><strong>💡 Tip:</strong> If you&rsquo;re using a specific colour scheme in your CV, apply the same one to your portfolio. It creates a cohesive look and helps make the connection between the two documents clear.</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/_Portfolio Cover 1.png" alt="Minimalist Cover Page - very simple Portfolio page - light background, with sample portflio, dates, name, job title and contact details"></img>
            {/* <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/keira_yang_zhan_cover_page.jpg" alt="Minimalist Cover Page by Keira Yang Zhan"></img> */}
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Sample Portfolio Cover 2.png" alt="Minimalist Cover Page - very simple Sample Portfolio page - dark background, with sample portflio, dates, name, job title and contact details"></img>
            <p/>
            <p/>
            <h3>Content Page</h3>
            <p>The second page of your sample or full portfolio should be used as a content page, giving the reader a clear overview of the projects you will showcase and what they can expect. This page helps set the tone and structure of your portfolio, making it easier for hiring managers to navigate through your work.</p>
            <p>Keep it simple and organised by listing the project titles, their corresponding pages and any relevant dates and locations. This will help the reader easily navigate and find what they&rsquo;re looking for.</p>
            <p/>
            <p/> 
            <h3>Choosing Which Projects to Showcase</h3>
            <p>Choosing projects for your Sample Portfolio can be tricky, but a good rule of thumb is to focus on 4 projects, each spanning 2 pages. Ask yourself: Does each project showcase different skills, sectors, or experiences? If not, leave it out.</p>
            <p><strong>💡 Tip:</strong> Here is a quick overview of how you could structure your 12-page Sample Portfolio</p>
            <ol>
            <li>Cover Page (1/12)</li>
            <li>Content Page (2/12)</li>
            <li>4 Projects (don't forget to include some technical detailing skills) (10/12)</li>
            <li>Hand Sketches / Physical Models / Photography / Academic Work (11/12)</li>
            <li>Thank you page (12/12)</li>
            </ol>
            <p>A Full Portfolio can include a broader range of projects: various sectors, stages, sustainability, hand sketches, model making, technical drawings and design work. You&rsquo;ll also have more space to show greater detail for each project, with each one easily taking up more than 5 pages.</p>
            <p><strong>💡 Tip:</strong> Avoid making the pages too busy; instead, focus on a clear layout that&rsquo;s easy to digest.</p>
            <p/>
            <p/>
            <h3>Organising Your Portfolio</h3>
            <p>Your portfolio should ideally follow a chronological order, starting with the most recent work.</p>
            <p>I understand that sometimes job adverts ask for specific sector experience and in that case, it would be worth adding that particular project first. However, try to work backwards from that point onwards. Present one project at a time and make sure the transitions between projects are clear (aka short overview of the projects - see the next section).</p>
            <p/>
            <p/>
            <h3>Description and Fonts</h3>
            <p>Each project should have a short description - aim for under 100 words. Keep it concise, especially for your Sample Portfolio. Hiring managers don&rsquo;t have time to read lengthy descriptions, so it&rsquo;s crucial to provide a brief, engaging overview.</p>
            <p>Key details to include in each description:</p>
            <ul>
            <li>Project Title/Location/Country</li>
            <li>Studio/Your Job Title at the time</li>
            <li>Type of Scheme (commercial, high-rise residential, mixed-use etc.)</li>
            <li>RIBA Stages/Scale (optional)</li>
            <li>Your Responsibilities (use bullet points)</li>
            <li>Software Used</li>
            </ul>
            <p>For fonts, stick to no more than two types (i.e. Arial, Times New Roman, or Calibri) and use 10-11 point font for the body text. Keep headings and subheadings larger to guide the reader.</p>
            <p><strong>💡 Tip:</strong> You can include more details in your Full Portfolio, such as the design brief, materials used, site information, etc.</p>
            <p/>
            <p/>
            <h3>Crediting Collaborative Work</h3>
            <p>When you&rsquo;ve worked with other professionals, such as visualisation specialists or architectural technologists, it&rsquo;s essential to credit them under each image. If you were in a senior role and managed the project, clarify your leadership in the process to avoid confusion during interviews. Here are ways to phrase this:</p>
            <ul>
            <li>"Image produced by [Name/Team/Third Party]."</li>
            <li>"Render by [Visualisation Studio/Colleague's Name]."</li>
            <li>"Drawing created by [Team/Consultant], overseen by [Your Name]."</li>
            <li>"Visualisation provided by [Name/External Agency]."</li>
            <li>"Design collaboration with [Name/Team]."</li>
            <li>"Produced by [Name/Team] under my direction.</li>
            </ul>
            <p/>
            <p/>
            <h3>Last Page</h3>
            <p>No matter the format, always include a thank-you page at the end of your portfolio with your contact details (name, phone number and email). This ensures that hiring managers have an easy way to reach you.</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Sample Portfolio Last page.png" alt="Minimalist sample portfolio thank you page with a dark background"></img>
            <p/>
            <p/>
            <h2>4. Formats for Your Portfolio: Printed, PDF or Online?&nbsp;</h2>
            <p>There are several ways to present your portfolio, each with its advantages:</p>
            <p/>

            <h3>Printed Portfolios</h3>
            <p>A printed portfolio is a more organic and personal way to present your work in person. However, it can take months to prepare and can be expensive to update regularly. If you choose to go this route, stick to A3 paper for a professional finish.</p>

            <p/>
            <h3>PDF Portfolios</h3>
            <p>A PDF portfolio is a quicker and more practical way to share and update your work. Most practices prefer receiving portfolios in this format, especially for a Sample Portfolio. When saving your file, name it appropriately (i.e. "Jane Doe - Sample Portfolio").</p>
  
            <p/>
            <h3>Online Portfolios</h3>
            <p>Using a portfolio hosting platform like Issuu or Behance can be a good option if you&rsquo;re a freelance architect or designer. However, if you&rsquo;re just starting out, a PDF portfolio is probably your best bet.</p>

            <p/>
            <h3>Build a Website</h3>
            <p>This is a popular option if you're a freelancer or run your own studio. It&rsquo;s a great way to attract new clients and showcase the projects you've worked on. However, you&rsquo;ll need to stay on top of it and ensure your website remains up to date.</p>
            <p/>
            <p/>
            <h2>5. Final Checks&nbsp;</h2>
            <p>When you are done creating your Portfolios, please take a moment to read and check it for the following things:</p>
            <ul>
            <li>Check your portfolio for any typos - this is very important.</li>
            </ul>
            <ul>
            <li>Check the layout - is it consistent throughout your portfolio?</li>
            <li>Are your personal and contact details included on both the first and last pages?</li>
            <li>Have you renamed your file to: First Name Last Name &ndash; Sample/Portfolio?</li>
            <li>Have you credited any images that you did not create yourself?</li>
            <li>Is each new project clearly labeled with a header and a short description?</li>
            <li>Have you included any technical drawings?</li>
            <li>Does your Sample Portfolio showcase a variety of skills and experiences?</li>
            <li>Ensure the file size isn&rsquo;t too large - especially for the Sample Portfolio (it should be under 10MB).</li>
            <li>Image quality must be high. If you need to compress your Sample Portfolio, double-check that the image quality hasn&rsquo;t been compromised.</li>
            </ul>
            <p/>
            <p/>
            <h2>Summary</h2>
            <p>If you're an architectural student or professional, having a portfolio is essential. There&rsquo;s no way around it. It showcases your skills, design abilities and how you&rsquo;ve evolved in your professional career.</p>
            <p>When applying for jobs, remember to submit your Sample Portfolio - a smaller version of your Full Portfolio. Once you're invited to an interview, you can present your Full Portfolio and provide more detailed information about specific projects.</p>
            <p>Please ensure that you have a clean and easy-to-follow layout and avoid overwhelming the reader with crowded pages and too much text.</p>
            <p/>
            <p/>
            <p><br /><strong>Disclaimer:</strong> I understand that there isn&rsquo;t a one-size-fits-all approach when it comes to Sample Portfolios. Some practices may require documents under 5MB, in landscape format or even with one image per page. The guidance above provides a solid starting point - particularly for junior professionals and is widely accepted by many practices in the UK and Dubai. However, always check the specific requirements of the practice you&rsquo;re applying to and tailor your portfolio accordingly. ✌️</p>
            </ArticleFrame>
    }
}

// Now, go to the App.js file
// add this line at the top, rename {YourArticle} to whatever you called it. You also need to copy and rename this file to "{YourArticle}.js"
// import YourArticle from './articles/YourArticle'
// Add another line in the Routes section, renaming "YourArticle" again:
// <Route path="/articles/YourArticle" element={<YourArticle/>} />


// now in TERMINAL below, run the following command:
// npm run-script dev
// head to the article page and review the html to make sure its properly formatted
//add <p/> between rows where you want a larger space
//add any images that were missed out (will need to upload these to article_images folder too) eg. <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_keywords.png" alt="Archjobs Keywords when applying"></img>
//add rel="nofollow noopener" to any links so it doesnt impact our SEO eg.  <a href="https://dictionary.cambridge.org/dictionary/english/cv" target="_blank" rel="nofollow noopener">Cambridge Dictionary</a>





// finally, publish the changes to github
// When its live, go to search console and inspect the new url: https://search.google.com/search-console?resource_id=sc-domain%3Aarchjobs.co.uk
// Might need a couple of attempts for it to get cached and display properly
// Make sure the rich text snippet is working properly
// If it is, request indexing from google to speed up the process